<template>
  <!--begin::Dashboard-->
  <div class="card-heade border-0" :class="widgetClasses">
    <!-- <nav class="navbar navbar-inverse">
            <div class="container-fluid">
            
                <ul class="nav navbar-nav">
                <li class="active"><a href="#">Home</a></li>
                <li><a href="#">Page 1</a></li>
                <li><a href="#">Page 2</a></li>
                </ul>
                
            </div>
        </nav> -->
    <!--  testing div start -->
    <!-- <div class="row gy-5 g-xl-8" style="margin-top:30Px;">
         <div class="col-xxl-4">
         {{ customplans.length}}
         </div>
          <div class="col-xxl-4">
              <div class="nav-group nav-group-outline mx-auto mb-15" data-kt-buttons="true">
         <a  class="btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2" data-kt-plan="month">Monthly</a>
         <a  class="btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 active" data-kt-plan="annual">Annual</a>
         </div>
         </div>
          <div class="col-xxl-4">
         
         </div>
            
         </div> -->
    <!--  testing div end -->

    <div class="row gy-5 g-xl-8">
      <div class="d-flex flex-column">
        <!--begin::Nav group-->
        <div class="nav-group nav-group-outline mx-auto" data-kt-buttons="true">
          <button
            @click="currencyType = 'usd'"
            :class="[currencyType === 'usd' && 'active']"
            class="btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2"
          >
            USD
          </button>
          <button
            @click="currencyType = 'inr'"
            :class="[currencyType === 'inr' && 'active']"
            class="btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3"
          >
            INR
          </button>
        </div>
      </div>
      <!--end::Nav group-->
    </div>

    <div class="row gy-5 g-xl-8">
      <!-- <template v-if="!isLoading" > -->

      <div
        class="col-xxl-4 col-xl-4 myclass flex-container"
        v-for="(item, index) in plans"
        :key="index"
        :ref="'div' + item.id"
        :class="['obj' + item.id]"
        style="display: flex; flex-direction: row"
      >
        <div class="d-flex h-100 align-items-center">
          <div
            class="w-100 d-flex flex-column flex-center rounded-3 bg-white py-15 px-10"
          >
            <!--begin::Heading-->
            <div class="mb-7 text-center w-100">
              <!--begin::Title-->
              <h1 class="text-dark mb-5 fw-boldest">{{ item.name }}</h1>
              <!--end::Title-->
              <!--begin::Description-->
              <div class="text-gray-400 fw-bold mb-5">
                {{ item.description }}
              </div>
              <!--end::Description-->
              <!--begin::Price &#8377;-->
              <div class="text-center d-none">
                <span class="mb-2 text-primary">{{
                  item.type == "usd" ? "$" : "₹"
                }}</span>
                <span
                  class="fs-3x fw-bolder text-primary"
                  data-kt-plan-price-month="39"
                  data-kt-plan-price-annual="399"
                  >{{ item.price }}</span
                >
                <span class="fs-7 fw-bold opacity-50"
                  >/ <span data-kt-element="period">mo</span></span
                >
              </div>
              <!--end::Price-->
              <!--begin::Price &#8377;-->
              <div
                class="text-left w-100 mb-10"
                style="position: relative"
                v-if="item.code == 'free'"
              >
                <div
                  class="border rounded-3 bg-light bg-gradient w-100 py-5 cursor-pointer text-left"
                >
                  <span class="fs-2x text-muted me-2">Always Free</span>
                </div>
              </div>
              <div
                class="text-left w-100 mb-1"
                style="position: relative"
                v-else
              >
                <div
                  class="border rounded-3 bg-light bg-gradient w-100 p-5 cursor-pointer text-left"
                  :class="{ 'border-primary': planHovered[item.code] }"
                  @mouseover="planHovered[item.code] = true"
                  @mouseleave="planHovered[item.code] = false"
                >
                  <div class="float-start ps-5">
                    <div>
                      <span class="fs-2x text-muted me-2"
                        >{{
                          planCodes[item.code][planCodeSelected[item.code]]
                            .period
                        }}
                        @</span
                      >
                      <span class="fs-2x fw-bolder text-primary"
                        >{{ item.type == "usd" ? "$" : "₹"
                        }}{{
                          planCodes[item.code][
                            planCodeSelected[item.code]
                          ].price.split(".")[0]
                        }}</span
                      >
                      <span class="fw-bolder text-primary"
                        >.{{
                          planCodes[item.code][
                            planCodeSelected[item.code]
                          ].price.split(".")[1]
                        }}</span
                      >
                      <span class="fs-7 fw-bold opacity-50">
                        / <span data-kt-element="period">mo</span>
                      </span>
                    </div>
                    <div
                      class="text-danger lead"
                      v-if="
                        planCodes[item.code][planCodeSelected[item.code]]
                          .savings > 0
                      "
                    >
                      SAVE
                      {{
                        planCodes[item.code][planCodeSelected[item.code]]
                          .savings
                      }}%
                    </div>
                  </div>
                  <div class="float-end">
                    <i
                      class="fas fa-angle-down fs-2x text-primary ms-5 mt-3"
                    ></i>
                  </div>
                  <div class="clearfix"></div>
                </div>
                <div
                  class="w-100 border border-primary plan_options"
                  v-show="planHovered[item.code]"
                  @mouseover="planHovered[item.code] = true"
                  @mouseleave="planHovered[item.code] = false"
                >
                  <div
                    class="border bg-light bg-gradient w-100 py-5 cursor-pointer"
                    v-for="(subPlan, spi) in planCodes[item.code]"
                    :key="spi"
                    @click="
                      (planCodeSelected[item.code] = spi),
                        (planHovered[item.code] = false)
                    "
                  >
                    <span class="fs-2x text-muted me-2"
                      >{{ subPlan.period }} @</span
                    >
                    <span class="fs-2x fw-bolder text-primary"
                      >{{ item.type == "usd" ? "$" : "₹"
                      }}{{ subPlan.price.split(".")[0] }}</span
                    >
                    <span class="fw-bolder text-primary"
                      >.{{ subPlan.price.split(".")[1] }}</span
                    >
                    <span class="fs-7 fw-bold opacity-50">
                      / <span data-kt-element="period">mo</span>
                    </span>
                    <div class="text-danger lead" v-if="subPlan.savings">
                      SAVE {{ subPlan.savings }}%
                    </div>
                  </div>
                </div>
              </div>
              <!--end::Price-->

              <div v-if="isUserAuthenticated">
                <span
                  class="badge rounded-pill bg-warning text-dark"
                  v-if="!mySubscription && item.code == 'free'"
                  >Selected</span
                >
                <span
                  class="badge rounded-pill bg-warning text-dark"
                  v-if="mySubscription && mySubscription.plan.code == item.code"
                  >Selected</span
                >
                <button
                  class="btn btn-sm btn-danger"
                  v-if="!mySubscription && item.code != 'free'"
                  @click="checkout(item.code)"
                >
                  Upgrade
                </button>
              </div>
              <div v-else>
                <router-link
                  :to="{
                    name: 'signUp',
                  }"
                  class="btn btn-sm btn-primary"
                  v-if="item.code == 'free'"
                  >Sign Up</router-link
                >
                <button
                  class="btn btn-sm btn-primary"
                  v-else
                  @click="checkout(item.code)"
                >
                  Select
                </button>
              </div>
            </div>
            <!--end::Heading-->
            <!--begin::Features-->
            <div class="w-100 mb-10">
              <!--begin::Item-->
              <div
                class="d-flex align-items-center mb-5"
                v-for="(feature, index1) in item.features"
                :key="index1"
              >
                <span
                  class="fw-bold fs-6 flex-grow-1 pe-3"
                  :class="
                    item.features[index1].status == 'active'
                      ? 'text-gray-800'
                      : 'text-gray-400'
                  "
                  >{{ item.features[index1].name }}</span
                >
                <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                <span
                  class="svg-icon svg-icon-1"
                  :class="
                    item.features[index1].status == 'active'
                      ? 'svg-icon-success'
                      : ''
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    v-if="item.features[index1].status == 'active'"
                  >
                    <rect
                      opacity="0.3"
                      x="2"
                      y="2"
                      width="20"
                      height="20"
                      rx="10"
                      fill="black"
                    ></rect>
                    <path
                      d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                      fill="black"
                    ></path>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    v-else
                  >
                    <rect
                      opacity="0.3"
                      x="2"
                      y="2"
                      width="20"
                      height="20"
                      rx="10"
                      fill="black"
                    ></rect>
                    <rect
                      x="7"
                      y="15.3137"
                      width="12"
                      height="2"
                      rx="1"
                      transform="rotate(-45 7 15.3137)"
                      fill="black"
                    ></rect>
                    <rect
                      x="8.41422"
                      y="7"
                      width="12"
                      height="2"
                      rx="1"
                      transform="rotate(45 8.41422 7)"
                      fill="black"
                    ></rect>
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </div>
              <!--end::Item-->
            </div>
            <!--end::Features-->
            <!--begin::Select-->

            <!--end::Select-->
          </div>
        </div>
      </div>
      <!-- </template> -->
    </div>

    <div
      class="d-flex flex-stack flex-wrap flex-md-nowrap card-rounded shadow p-8 p-lg-12 mb-n5 mb-lg-n13 mt-10"
      style="background: linear-gradient(90deg, #20aa3e 0%, #03a588 100%)"
    >
      <!--begin::Content-->
      <div class="my-2 me-5">
        <!--begin::Title-->
        <div class="fs-1 fs-lg-2qx fw-bolder text-white mb-2">
          Enterprise Solution <span class="fw-normal"></span>
        </div>
        <!--end::Title-->
        <!--begin::Description-->
        <div class="fs-6 fs-lg-5 text-white fw-bold opacity-75 d-none">
          More than 100 concurrent users in a session
        </div>
        <!--end::Description-->
      </div>
      <!--end::Content-->
      <!--begin::Link-->
      <a
        :href="webURL + '/contact-us'"
        class="btn btn-lg btn-outline border-2 btn-outline-white flex-shrink-0 my-2"
        >Contact Us</a
      >
      <!--end::Link-->
    </div>
  </div>
  <!--end::Dashboard-->
</template>
<script>
import { defineComponent, onMounted, computed, ref, toRefs, watch } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import { useForm, useField } from "vee-validate";
import * as yup from "yup";

import { Actions, Modules } from "@/store/enums/StoreEnums";

import Swal from "sweetalert2/dist/sweetalert2.js";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { formatDateTimePHP } from "@/core/helpers/date";

export default {
  name: "AllPlans",
  components: {},
  props: {
    // widgetClasses: String,
    // plans: Array,
    // isLoading: Boolean,
    // mySubscription: Boolean,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    // const { plans } = toRefs(props);
    const planCodes = {};
    const planCodeSelected = ref({});
    const planHovered = ref({});
    const currencyType = ref("usd");

    let isLoading = computed(() => {
      return store.getters[Modules.PLAN + "getIsLoading"];
    });
    let plans = computed(() => {
      return store.getters[Modules.PLAN + "getPlans"];
    });
    let mySubscription = computed(() => {
      return store.getters[Modules.PLAN + "getMySubscription"];
    });
    const isUserAuthenticated = computed(() => {
      return store.getters.isUserAuthenticated;
    });

    store.dispatch(Modules.PLAN + Actions.FETCH_PLANS, currencyType.value);
    if (isUserAuthenticated.value) {
      store.dispatch(Modules.PLAN + Actions.FETCH_MY_SUBSCRIPTION);
    }

    setPlanCodes(plans.value);

    watch(plans, (value) => {
      setPlanCodes(value);
    });

    watch(currencyType, () => {
      store.dispatch(Modules.PLAN + Actions.FETCH_PLANS, currencyType.value);
    });

    function setPlanCodes(myPlans) {
      if (myPlans && myPlans.length > 0)
        myPlans.forEach(function (plan, index) {
          planCodes[plan.code] = {};
          planCodes[plan.code][plan.code] = {
            id: plan.id,
            code: plan.code,
            period: planPeriodText(plan.period),
            price: plan.price_per_month,
            savings: plan.discount_percent,
          };
          planCodeSelected.value[plan.code] = ref(plan.code);
          planHovered.value[plan.code] = ref(false);
          if (plan.sub_plans.length > 0)
            plan.sub_plans.forEach(function (subPlan, index) {
              planCodes[plan.code][subPlan.code] = {
                id: subPlan.id,
                code: subPlan.code,
                period: planPeriodText(subPlan.period),
                price: subPlan.price_per_month,
                savings: subPlan.discount_percent,
              };
              planCodeSelected.value[plan.code] = ref(subPlan.code);
            });
        });
    }

    function planPeriodText(period) {
      switch (period) {
        case 1:
          return "1 Month";
        case 3:
          return "3 Months";
        case 6:
          return "6 Months";
        case 12:
          return "1 Year";
      }
    }

    const checkout = (code) => {
      router.push({
        name: "checkout",
        params: { code: planCodeSelected.value[code] },
      });
    };

    return {
      webURL: process.env.VUE_APP_WEB_URL,
      publicPath: process.env.BASE_URL,

      isUserAuthenticated,

      planCodes,
      planCodeSelected,
      planHovered,
      checkout,
      currencyType,

      isLoading,
      plans,
      mySubscription,
    };
  },
};
</script>
<style scoped>
.plan_options {
  position: absolute;
  top: 74px;
}
</style>
