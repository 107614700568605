
import { useStore } from "vuex";
import { ref, defineComponent, onMounted, computed } from "vue";
import { Actions, Modules } from "@/store/enums/StoreEnums";
import GridPlans from "@/views/AllPlansGrid.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "plans",
  components: {
    GridPlans,
  },
  setup() {
    const store = useStore();

    const config = computed(() => {
      return store.getters[Modules.APP + "getConfig"];
    });

    // let isLoading = computed(() => {
    //   return store.getters[Modules.PLAN + "getIsLoading"];
    // });
    // let plans = computed(() => {
    //   return store.getters[Modules.PLAN + "getPlans"];
    // });
    // let mySubscription = computed(() => {
    //   return store.getters[Modules.PLAN + "getMySubscription"];
    // });
    // const isUserAuthenticated = computed(() => {
    //   return store.getters.isUserAuthenticated;
    // });

    onMounted(() => {
      setCurrentPageTitle("All Plans");
    });

    // store.dispatch(Modules.PLAN + Actions.FETCH_PLANS);
    // if (isUserAuthenticated.value) {
    //   store.dispatch(Modules.PLAN + Actions.FETCH_MY_SUBSCRIPTION);
    // }

    return {
      // isLoading,
      // plans,
      // mySubscription,
      config,
    };
  },
});
